import React from 'react';
import {Container, Row, Col, Spinner} from 'react-bootstrap';

import './UserGameHistory.scss';

// modules
import UserDetails from './modules/UserDetails';
import GameDetails from './modules/GameDetails';
import GameActions from './modules/GameActions';

// services
import { LangService } from './services/LangService';

interface IUserGameHistoryProps {
    config: {
      restUrl: string,
      languages: string[]
    };
    urlProps: any;
}
interface IUserGameHistoryState {
    data: any;
    loading: boolean;
    error: any;
    lang: any;
    pack: any;
}

export class UserGameHistory extends React.Component<IUserGameHistoryProps, IUserGameHistoryState> {

   lang = LangService.getInstance();

    constructor(props: IUserGameHistoryProps) {
        super(props);

        this.state = {
            data: undefined,
            error: '',
            loading: true,
            lang: undefined,
            pack: undefined
        };

     }

    componentDidMount() {

      this.lang.init();

      const token: string = '';

      // lang set
      this.setState({lang: (this.props.urlProps.lang)? this.props.urlProps.lang : 'en' });

      // procede url properties
      if ( this.props.urlProps?.token) { // result && this.props.data.restUrl

        fetch(this.props.config.restUrl + this.props.urlProps.token)
        .then( (response: any) => {
          return response.json();
        })
        .then( (data: any) =>  {

          if (data.success) {
            this.setState({ data: data.data, loading: false });
          } else {
            this.setState({ error: data, loading: false });
          }

        });

      } else {
        this.setState({ error: {succes: false, error: 'Missing token parameter', errorCode:'e0104'}, loading: false });
        // invalid token
      }

    }

    langChange(ev: any) {
      ev.preventDefault();
      const set = ev.target.value;
      this.setState((state) => ({lang: set}));
    }

    render() {

       function getMessageBox(msg: string) {
          return <>
                <div className='ugh-message-box'>{msg}</div>
                </>;
        }

       const maintitle = ( this.lang.GetSingleTranslation('misc','title',this.state.lang) ) ?  this.lang.GetSingleTranslation('misc','title',this.state.lang) : 'main_title';
       const langlabel = ( this.lang.GetSingleTranslation('misc','langlabel',this.state.lang) ) ?  this.lang.GetSingleTranslation('misc','langlabel',this.state.lang) : 'select_language';
       const spinnerlabel = ( this.lang.GetSingleTranslation('misc','spinnerlabel',this.state.lang) ) ?  this.lang.GetSingleTranslation('misc','spinnerlabel',this.state.lang) : 'please_wait_label';

       return (
            <>
            <div className='ugh-header'>
              <Container>
                <Row>
                    <Col>
                      <div className='ugh-header-cred'>
                        <div className='ugh-header-title'>
                            { maintitle }
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className='ugh-header-menu'>
                          {this.props.config.languages &&
                            <div className='ugh-header-langselect'>
                                <label> { langlabel } </label>
                                <select value={this.state.lang} onChange={(evt) => this.langChange(evt)}>
                                    {this.props.config.languages.map((value,index) => {
                                      return  <option key={index.toString()} value={value}>{value}</option>;
                                    })}
                                </select>
                            </div>
                          }
                      </div>
                    </Col>
                  </Row>
              </Container>
            </div>
            <div className='ugh-content'>
              <Container>
                 {this.state.data ? (
                      <>
                      <Row className='ugh-section ugh-section--info'>
                          <Col xs={12} md={4}>
                            { this.state.data.request ? (
                                <UserDetails
                                  data={this.state.data.request}
                                  lang={ { pack: this.lang.GetTranslations('request'), set: this.state.lang} }>
                                </UserDetails>
                            ) : (
                              getMessageBox(this.lang.GetSingleTranslation('error',this.state.data.actions.errorCode,this.state.lang))
                            )}

                          </Col>
                          <Col xs={12} md={8}>
                            { this.state.data.cycle.success !== false ? (
                                <GameDetails
                                  data={this.state.data.cycle}
                                  lang={ { pack: this.lang.GetTranslations('cycle'), set: this.state.lang} }>
                                </GameDetails>
                            ) : (
                              getMessageBox(this.lang.GetSingleTranslation('error',this.state.data.actions.errorCode,this.state.lang))
                            )}
                          </Col>

                      </Row>

                      <Row className='ugh-section ugh-section--action'>
                        <Col>
                            { this.state.data.actions.length > 0 ? (
                                <GameActions
                                  data={this.state.data.actions}
                                  lang={ { pack: this.lang.GetTranslations('actions'), set: this.state.lang} }
                                  currency={this.state.data?.cycle?.currency_code}>
                                </GameActions>
                            ) : (
                              getMessageBox(this.lang.GetSingleTranslation('error',this.state.data.actions.errorCode,this.state.lang))
                            )}
                        </Col>
                      </Row>

                      </>
                  ) : (
                      <Row className='ugh-section ugh-section--info'>
                        <Col>
                        {this.state.loading ? (
                          <>
                            <div className='ugh-spinner'>
                              <Spinner animation='border'/>
                              <div className='ugh-spinner-title'>{spinnerlabel}</div>
                            </div>
                          </>
                        ) : (
                          getMessageBox(this.lang.GetSingleTranslation('error',this.state.error.errorCode,this.state.lang))
                        )}
                        </Col>
                      </Row>
                  )}
              </Container>
            </div>
            </>
          );
    }
}

export default UserGameHistory;
