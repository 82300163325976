import React from 'react';
import Currency from 'react-currency-formatter';

import './GameActions.scss';

interface IGameActionsProps {
  data: any;
  lang: {
    pack: any,
    set: string
  };
  currency: string;
}
interface IGameActionsState {}

export class GameActions extends React.Component<IGameActionsProps, IGameActionsState> {

    constructor(props: IGameActionsProps) {
        super(props);

        this.state = {};
     }

    componentDidMount() {}

    render() {

        const langset = this.props.lang.set;
        const langpack = this.props.lang.pack;

        function getSymbolsBlock(data: string) {
            if (data === 'null') { return true; }
            const array = JSON.parse(data);
            return  <>
                    <div className='symbolsGrid'>
                    {array.map((value: any, index: any) => {
                      return <div className='symbolsGrid--col' key={index.toString()}>
                        {value.map((value: any, index: any) => {
                          return <div className='symbolsGrid--item' key={index.toString()}>{value}</div>;
                        })}
                     </div>;
                    })}
                    </div>
                    </>;
        }

        function getWinlinesBlock(data: string) {
          if (data === 'null') { return true; }
          const array = JSON.parse(data);
          return <>
                  {array.map((value: any, index: any) => {
                    return <span key={index.toString()}>{value} / </span>;
                  })}
                </>;
        }

        return (
          <>
          <div className='ugh-informations--panel'>

            <h1>
              { langpack?.section_header?.[langset] ? langpack.section_header[langset] : 'Section_header'}
            </h1>

            {this.props.data?.map((value: any, index: { toString: () => string | number | undefined; }) => {

                return <div className='gameActions-listItem' key={index.toString()}>
                          <div className='gameActions-itemCol'>
                            { langpack?.actionno?.[langset] ? langpack.actionno[langset] : 'actionno'}:&nbsp;
                            { index.toString() }
                          </div>
                          <div className='gameActions-itemCol'>

                            <div className='gameActions--option'>
                              <label>
                                { langpack?.type_name?.[langset] ? langpack.type_name[langset] : 'type_name'}:&nbsp;
                              </label>
                              <span>{value.type_name}</span>
                            </div>

                            <div className='gameActions--option'>
                              <label>
                                { langpack?.bet?.[langset] ? langpack.bet[langset] : 'bet'}:&nbsp;
                              </label>
                              <span>
                                <Currency quantity={parseInt(value.bet,10)} currency={this.props.currency}/>
                              </span>
                            </div>

                            <div className='gameActions--option'>
                              <label>
                                { langpack?.win?.[langset] ? langpack.win[langset] : 'bet'}:&nbsp;
                              </label>
                              <span>
                                <Currency quantity={parseInt(value.win,10)} currency={this.props.currency}/>
                              </span>
                            </div>
                            {this.props.currency !== 'USD' &&
                              <div className='gameActions--option'>
                                <label>
                                  { langpack?.denomination?.[langset] ? langpack.denomination[langset] : 'denomination'}:&nbsp;
                                </label>
                                <span>
                                  1/100
                                </span>
                              </div>
                            }
                          </div>
                          <div className='gameActions-itemCol'>

                            <div className='gameActions--option'>
                              <label>
                                { langpack?.action_time?.[langset] ? langpack.action_time[langset] : 'action_time'}:&nbsp;
                              </label>
                              <span>
                                {new Intl.DateTimeFormat('en-GB', {
                                    month: 'long',
                                    day: '2-digit',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                }).format(new Date(value.action_time))}
                              </span>
                            </div>

                            <div className='gameActions--option'>
                              <label>
                                { langpack?.scatter_game_win?.[langset] ? langpack.scatter_game_win[langset] : 'scatter_game_win'}:&nbsp;
                              </label> <span> {value.scatter_game_win}</span>
                            </div>

                              <div className='gameActions--option'>
                                <label>
                                  { langpack?.scatter_win?.[langset] ? langpack.scatter_win[langset] : 'scatter_game_win'}:&nbsp;
                                </label> <span> {value.scatter_win}</span>
                              </div>

                              <div className='gameActions--option'>
                                <label>
                                  { langpack?.winlines?.[langset] ? langpack.winlines[langset] : 'winlines'}:&nbsp;
                                </label>
                                <span> { getWinlinesBlock(value.winlines) }</span>
                              </div>

                          </div>
                          <div className='gameActions-itemCol'>
                            { getSymbolsBlock(value.symbols) }
                          </div>
                      </div>;
            })}
          </div>
          </>
          );
    }
}

export default GameActions;
