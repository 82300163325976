import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import UserGameHistory from './UserGameHistory';

const config = window.UGHConfig.app;
const urlProp: any = {};

const gameURL: string = window.location.href;
const requestProps = gameURL.match(/[^&?]*?=[^&?]*/g);

if (requestProps) {
    requestProps.forEach( (value, key) => {
        const prop = value.split('=');
        urlProp[prop[0]] = prop[1];
     });
}

ReactDOM.render(
  <React.StrictMode>
    <UserGameHistory config={config}  urlProps={urlProp}/>
  </React.StrictMode>,
  document.getElementById('root')
);
