import React from 'react';

import './UserDetails.scss';

interface IUserDetailsProps {
  data: any;
  lang: {
    pack: any,
    set: string
  };
}
interface IUserDetailsState {}

export class UserDetails extends React.Component<IUserDetailsProps, IUserDetailsState> {

    constructor(props: IUserDetailsProps) {
        super(props);

        this.state = {};
     }

    componentDidMount() {}

    render() {

        const langset = this.props.lang.set;
        const langpack = this.props.lang.pack;

        return (
            <>
            <div className='ugh-informations--panel'>
                <h1>
                  { langpack?.section_header?.[langset] ? langpack.section_header[langset] : 'Section_header'}
                </h1>

                <div className='ugh-informations--option'>
                  <label> { langpack?.game_id?.[langset] ? langpack.game_id[langset] : 'game_id'}: </label>
                  <span>{ this.props.data.game_id }</span>
                </div>

                <div className='ugh-informations--option'>
                  <label>{ langpack?.player_id?.[langset] ? langpack.player_id[langset] : 'player_id'}:</label>
                  <span>{this.props.data.player_id}</span>
                </div>

                <div className='ugh-informations--option'>
                  <label>{ langpack?.skin_id?.[langset] ? langpack?.skin_id[langset] : 'skin_id'}:</label>
                  <span>{this.props.data.skin_id}</span>
                </div>
             </div>
            </>
          );
    }
}

export default UserDetails;
