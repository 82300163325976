import React from 'react';
import Currency from 'react-currency-formatter';

import './GameDetails.scss';

interface IGameDetailsProps {
  data: any;
  lang: {
    pack: any,
    set: string
  };
}
interface IGameDetailsState {}

export class GameDetails extends React.Component<IGameDetailsProps, IGameDetailsState> {

    constructor(props: IGameDetailsProps) {
        super(props);
        this.state = {};
     }

    componentDidMount() { }

    render() {

        const langset = this.props.lang.set;
        const langpack = this.props.lang.pack;

        return (
            <>
            <div className='ugh-informations--panel'>
                <h1>
                    { langpack?.section_header?.[langset] ? langpack.section_header[langset] : 'Section_header'}
                </h1>

                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.start_date_time?.[langset] ? langpack.start_date_time[langset] : 'start_date_time'}:
                    </label>
                    <span>
                        {new Intl.DateTimeFormat('en-GB', {
                          month: 'long',
                          day: '2-digit',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                      }).format(new Date(this.props.data.start_date_time))}
                    </span>
                </div>

                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.end_date_time?.[langset] ? langpack.end_date_time[langset] : 'end_date_time'}:
                    </label>
                    <span>
                        {new Intl.DateTimeFormat('en-GB', {
                          month: 'long',
                          day: '2-digit',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                      }).format(new Date(this.props.data.end_date_time))}
                    </span>
                </div>

                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.session_id?.[langset] ? langpack.session_id[langset] : 'session_id'}:
                    </label>
                    <span>
                        {this.props.data.session_id}
                    </span>
                </div>
{/*
                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.id?.[langset] ? langpack.id[langset] : 'game_cycle_id'}:
                    </label>
                    <span>
                        {this.props.data.id}
                    </span>
                </div>
*/}
                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.locale_code?.[langset] ? langpack.locale_code[langset] : 'locale_code'}:
                    </label>
                    <span>
                        {this.props.data.locale_code}
                    </span>
                </div>

                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.currency_code?.[langset] ? langpack.currency_code[langset] : 'currency_code'}:
                    </label>
                    <span>
                        {this.props.data.currency_code}
                    </span>
                </div>

                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.balance_before?.[langset] ? langpack.balance_before[langset] : 'balance_before'}:
                    </label>
                    <span>
                        <Currency quantity={parseInt(this.props.data.balance_before,10)} currency={this.props.data.currency_code}/>
                    </span>
                </div>

                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.balance_after?.[langset] ? langpack.balance_after[langset] : 'balance_after'}:
                    </label>
                    <span>
                        <Currency quantity={parseInt(this.props.data.balance_after,10)} currency={this.props.data.currency_code}/>
                    </span>
                </div>

                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.bet?.[langset] ? langpack.bet[langset] : 'bet'}:
                    </label>
                    <span>
                        <Currency quantity={parseInt(this.props.data.bet,10)} currency={this.props.data.currency_code}/>
                    </span>
                </div>

                <div className='ugh-informations--option'>
                    <label>
                        { langpack?.win?.[langset] ? langpack.win[langset] : 'win'}:
                    </label>
                    <span>
                        <Currency quantity={parseInt(this.props.data.win,10)} currency={this.props.data.currency_code}/>
                    </span>
                </div>

             </div>
            </>
          );
    }
}

export default GameDetails;
