export class LangService {

    private static instance: LangService;

    tranlsations: any;

    // tslint:disable-next-line:no-empty
    constructor() { }

    static getInstance(): LangService {
        if (!LangService.instance) {
            LangService.instance = new LangService();
        }
        return LangService.instance;
    }

    init() {
        this.tranlsations = (window.UGHConfig?.translations) ? window.UGHConfig.translations : undefined;
    }

    // tslint:disable-next-line:typedef
    GetTranslations(object: string) {
        if (this.tranlsations) {
            return  (this.tranlsations[object])?  this.tranlsations[object] : undefined;
        } else {
            return undefined;
        }
    }

    GetSingleTranslation(object: string, key: string, language: string) {
        if (this.tranlsations) {
            return  ( this.tranlsations[object] && this.tranlsations[object][key] && this.tranlsations[object][key][language]) ?  this.tranlsations[object][key][language] : key;
        } else {
            return undefined;
        }
    }

}
